import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { ParallaxProvider } from "react-scroll-parallax"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Cookies from "../components/Cookies/Cookies"

const Content = props => {
  const artwork = props.pageContext.post

  let isContent
  if (artwork !== undefined) {
    isContent = true
  }

  return (
    <>
      <Helmet>
        <html lang="cs" />
        <meta charSet="utf-8" />
        <title>
          {artwork.author} {artwork.surname} · Magnus Art
        </title>
        <meta
          name="description"
          content="J&T Banka vybudovala vlastní sbírku současného umění složenou z děl laureátů Ceny Jindřicha Chalupeckého."
        />
      </Helmet>
      <ParallaxProvider>
        <Header
          {...props}
          id={artwork.directusId}
          date={artwork.year}
          author={artwork.author}
          surname={artwork.surname}
          category={artwork.category}
          isContentIn={isContent}
        />
        <Cookies />
        <main className="main-content" key={artwork.directusId}>
          <div
            className="text-left mb-3 w-50 w-100"
            dangerouslySetInnerHTML={{ __html: artwork.intro }}
          ></div>

          <div
            className={
              "picture-container center" +
              (artwork.viewport[0] === "portrait" ? " portrait" : "")
            }
            style={
              artwork.viewport[0] === "portrait"
                ? {
                    width:
                      artwork.image.localFile.childImageSharp.fluid
                        .presentationWidth,
                  }
                : null
            }
          >
            <p className="text-right medium">{artwork.title}</p>
            <p className="text-right">{artwork.technique}</p>
            <p className="text-right">{artwork.size}</p>
            <Img
              className="picture-container__image"
              loading="eager"
              fluid={artwork.image.localFile.childImageSharp.fluid}
              alt={artwork.title}
            />
          </div>

          <div
            className="picture-content"
            dangerouslySetInnerHTML={{ __html: artwork.content }}
          ></div>
        </main>

        <Footer />
      </ParallaxProvider>
    </>
  )
}

export default Content

export const query = graphql`
  query {
    allDirectusArtwork {
      edges {
        node {
          slug
          directusId
          category
          author
          surname
          content
          intro
          title
          year
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  maxHeight: 1024
                  srcSetBreakpoints: [960, 1920]
                  quality: 90
                  fit: INSIDE
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  presentationWidth
                  sizes
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
          }
          technique
          size
          viewport
        }
      }
    }
  }
`
